import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { FaInstagram } from "react-icons/fa";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Home | Caroline Boseley"
				description="Home page for Caroline Boseley website"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | Caroline Boseley",
					description: "Home page for Caroline Boseley website",
				}}
			/>

			<div>
				<Layout>
					<link rel="stylesheet" href="https://use.typekit.net/jrd7irp.css" />
					<section className="bg-primary">
						<Container className="vh-100">
							<Row className="h-100 align-items-center text-secondary">
								<Col>
									<p className="aktiv fs-5 ">New website coming soon</p>
									<div className="d-inline-block">
										<h1 className="d-inline-block text-secondary display-1 history text-uppercase ">
											Caroline Boseley
										</h1>
										<hr
											style={{ opacity: 1, height: "2px" }}
											className="bg-secondary"
										/>
									</div>
									<div className="d-flex align-items-center">
										<a
											className="link-secondary me-3 me-md-5 fs-2 mb-2"
											href="https://www.instagram.com/carolineboseley/"
											target="_blank"
											rel="noreferrer"
										>
											<FaInstagram />
										</a>
										<a
											className="text-uppercase d-none d-md-block history pb-0 fs-5 mb-0 link-secondary"
											href="mailto:caroline@carolineboseley.com"
										>
											caroline@carolineboseley.com
										</a>
										<a
											className="text-uppercase history d-md-none pb-0  mb-0 link-secondary"
											href="mailto:caroline@carolineboseley.com"
										>
											caroline@carolineboseley.com
										</a>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
